<template>
  <div class="layout-basic-page full-height">
    <div class="content">
      <h1 class="">Disclaimer</h1>
      <div class="body ng-scope">
        <p>
          These terms and conditions (Terms) apply to the use of the Ride to
          Live website (Site), including the use of the information provided on
          the Site. In order to be able to access the information offered on
          this Site, you must agree to these Terms. These Terms must be read in
          conjunction with any other applicable terms and warnings governing the
          use of the Site. In these Terms, the expressions we, us and our are a
          reference to Transport for NSW, Level 6, 18 Lee Street, Chippendale
          NSW 2008 (TfNSW).
        </p>
        <h3>Disclaimer</h3>
        <p>
          The information on this Site is intended to give only an indication of
          your ability to assess and manage the risks of motorcycle riding. You
          accept that there are many factors that may in reality affect your
          ability to respond to the theoretical hazards and risks posed on this
          Site. You also accept that the information provided through the Site
          does not indicate whether you will be able to navigate the hazards and
          risks successfully in practice, and is not in the nature of advice. It
          remains your responsibility at all times to ensure you are a licensed,
          capable and safe rider.
        </p>
        <p>
          Further, we do not make any representation or warranty that the
          information provided is reliable, accurate or complete. You should
          make your own enquiries and seek independent advice before acting or
          relying on any information or material which is made available to you
          pursuant to this Site. It remains your responsibility to evaluate the
          accuracy, completeness and usefulness of any such information. We do
          not accept responsibility for any losses, damages, liabilities,
          claims, costs and expenses, however caused (including through
          negligence), which you may directly or indirectly suffer in connection
          with or arising from your use of the Site or your use of or reliance
          on information contained on or accessed through this Site.
        </p>
        <p>
          You must ensure that your access to this Site is not illegal or
          prohibited by laws which apply to you. You agree to use the Site for
          lawful purposes only.
        </p>
        <p>
          You must take your own precautions to ensure that the process which
          you employ for accessing this Site does not expose you to the risk of
          viruses, malicious computer code or other forms of interference which
          may damage your own computer system. For the removal of doubt, we do
          not accept responsibility and you release TfNSW in respect of any
          losses, damages, liabilities, claims, costs and expenses which may be
          suffered directly or indirectly for any interference or damage to your
          own computer system which arises in connection with your use of the
          Site.
        </p>
        <p>
          To the extent permitted by law, any condition or warranty which would
          otherwise be implied into these Terms is excluded. Our liability for
          breach of a condition, warranty or guarantee implied by or arising
          under law which cannot be excluded is limited to the extent permitted
          by law to the resupply of the goods or services as the case may be.
          You indemnify us and our officers, employees and agents against all
          actions, claims and demands (including the cost of defending or
          settling any action, claim or demand) which may be instituted against
          us arising out of a failure by you to comply with these Terms.
        </p>
        <p>
          The Site may contain links to other websites. Those links are provided
          for convenience only and may not remain current or be maintained. We
          are not responsible for the content or privacy practices associated
          with any third party websites. Our links to such websites should not
          be construed as an endorsement, approval, affiliation with or
          recommendation by TfNSW of any information, graphics, materials,
          products or services referred to or contained on those websites,
          unless and to the extent stipulated to the contrary.
        </p>
        <h3>Road Rules</h3>
        <p>
          You must comply with all applicable road rules, including in NSW, the
          NSW Road Rules and not use this Site in any manner contrary to such
          road rules.
        </p>
        <p>
          The NSW Road Rules provide that it is illegal to use a mobile phone
          held in your hand while you are driving or stationary (but not
          parked). A driver of a motor vehicle may only use a mobile phone to
          make or receive a phone call while driving if the phone is secured in
          a mounting fixed to the vehicle, or can be operated by the driver
          without touching or manipulating any part of the phone for example
          through the use of Bluetooth technology and the phone is not resting
          on any part of the driver's body.
        </p>
        <h3>Copyright</h3>
        <p>
          Copyright in the Site (including text, graphics, logos, icons, sound
          recordings and software) is owned or licensed by us. Information
          procured from a third party may be the subject of copyright owned by
          that third party. Other than for the purposes of, and subject to the
          conditions prescribed under, the Copyright Act 1968 (Cth) and similar
          legislation which applies in your location, and except as expressly
          authorised by these Terms, you may not in any form or by any means
          adapt, reproduce, store, distribute, print, display, perform, publish
          or create derivative works from any part of this Site or from any
          information obtained from this Site or commercialise any information,
          products or services obtained from any part of this Site without our
          written permission.
        </p>
        <h3>Restricted Use</h3>
        <p>
          Unless we agree otherwise in writing, you are provided with access to
          this Site only for your personal use. Without limiting the foregoing,
          you may not without our written permission onsell information obtained
          from this Site.
        </p>
        <h3>Privacy Policy</h3>
        <p>
          We undertake to comply with the terms of our privacy policy which is
          accessible from
          <a
            href="https://www.transport.nsw.gov.au/about-us/contact/privacy-statement"
            >https://www.transport.nsw.gov.au/about-us/contact/privacy-statement</a
          >
          and our online privacy policy which is accessible from
          <a
            href="https://www.transport.nsw.gov.au/about-us/contact/privacy-statement"
            >www.transport.nsw.gov.au/about-us/contact/privacy-statement</a
          >.
        </p>
        <h3>Security of Information</h3>
        <p>
          Unfortunately, no data transmission over the Internet can be
          guaranteed as totally secure. Whilst we strive to protect such
          information, we do not warrant and cannot ensure the security of any
          information which you transmit to us. Accordingly, any information
          which you transmit to us is transmitted at your own risk.
          Nevertheless, once we receive your transmission, we will take
          reasonable steps to preserve the security of such information.
        </p>
        <h3>Termination of Access</h3>
        <p>
          Access to this Site may be terminated at any time by us without
          notice. Our disclaimer will nevertheless survive any such termination.
        </p>
        <h3>General</h3>
        <p>
          These Terms are governed by the laws in force in New South Wales,
          Australia. You agree to submit to the exclusive jurisdiction of the
          courts of that jurisdiction.
        </p>
        <p>
          We reserve the right to amend or vary these Terms at any time at our
          discretion. Your continued use of the Site will be taken as your
          acceptance of the Terms as amended or varied.
        </p>
        <p>
          We accept no liability for any failure to comply with these Terms
          where such failure is due to circumstance beyond our reasonable
          control.
        </p>
        <p>
          If we waive any rights available to us under these Terms on one
          occasion, this does not mean that those rights will automatically be
          waived on any other occasion.
        </p>
        <p>
          If any of these terms are held to be invalid, unenforceable or illegal
          for any reason, the remaining terms shall nevertheless continue in
          full force.
        </p>
        <h3>To Return to the Site</h3>
        <p>
          To return to the Site, click where indicated. By doing so, you
          acknowledge that you have read, understood and accept the above Terms.
        </p>
        <hr />
        <p>
          <a
            href="/sites/default/files/documents/site_terms.pdf"
            target="_blank"
            >Download this page as a PDF</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "site-terms",
  components: {}
};
</script>
